<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
import zh_CN from 'ant-design-vue/es/locale/zh_CN'

export default {
  name: 'app',
  data() {
    return {
      locale: zh_CN,
    }
  },
  created() {
    // console.log('APPApp')
  },
  destroyed() {
    sessionStorage.clear()
    localStorage.clear()
  },
}
</script>

<style lang="less" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>
