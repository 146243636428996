/*
 * @Author: XSJ 13592841305@163.com
 * @Date: 2023-06-26 16:15:27
 * @LastEditors: XSJ 13592841305@163.com
 * @LastEditTime: 2023-08-22 10:08:37
 * @FilePath: \cz-operation\src\api\systemServe\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE。。。
 */
import { systemManageApi } from './systemManageApi.js'
import { apiListManageApi } from './apiListManage.js'
import { gatewayRoutingApi } from './gatewayRouting.js'

export const systemServe = {
  ...systemManageApi,
  ...apiListManageApi,
  ...gatewayRoutingApi,
}
