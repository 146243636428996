<!--
 * @Author: 徐少杰 xushaojie@yihuosoft.com
 * @Date: 2024-05-23 15:30:29
 * @LastEditors: 徐少杰 xushaojie@yihuosoft.com
 * @LastEditTime: 2024-05-23 15:31:22
 * @FilePath: \cz-operation-web\src\components\Ellipsis\Ellipsis.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script>
import Tooltip from 'ant-design-vue/es/tooltip'
import { cutStrByFullLength, getStrFullLength } from '@/utils/util.js'

export default {
  name: 'Ellipsis',
  components: { Tooltip },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-ellipsis'
    },
    tooltip: {
      type: Boolean
    },
    length: {
      type: Number,
      required: true
    },
    lines: {
      type: Number,
      default: 1
    },
    fullWidthRecognition: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getStrDom(str, fullLength) {
      return (
        <span>
          {cutStrByFullLength(str, this.length) + (fullLength > this.length ? '...' : '')}
        </span>
      )
    },
    getTooltip(fullStr, fullLength) {
      return (
        <Tooltip>
          <template slot="title">{fullStr}</template>
          {this.getStrDom(fullStr, fullLength)}
        </Tooltip>
      )
    }
  },
  render() {
    const { tooltip, length } = this.$props
    const str = this.$slots.default.map(vNode => vNode.text).join('')
    const fullLength = getStrFullLength(str)
    const strDom =
      tooltip && fullLength > length
        ? this.getTooltip(str, fullLength)
        : this.getStrDom(str, fullLength)
    return strDom
  }
}
</script>
