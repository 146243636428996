import { axios } from '@/utils/request'
import { stringify } from 'qs'

/* 获取api接口信息列表 */
export function getApiInfoList(params) {
  return axios({
    url: `/base/api`,
    method: 'get',
    params,
  })
}

/* 获取网关服务列表 */
export function getGatewayServiceList() {
  return axios({
    url: `/base/gateway/service/list`,
    method: 'get',
  })
}

/* 新增api接口 */
export function addApi(data) {
  return axios({
    url: `/base/api/add`,
    method: 'post',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 删除api接口 */
export function deleteApi(data) {
  return axios({
    url: `/base/api/remove`,
    method: 'post',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 修改api */
export function updateApi(data) {
  return axios({
    url: `/base/api/update`,
    method: 'post',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 批量删除api */
export function batchDeleteApi(data) {
  return axios({
    url: `/base/api/batch/remove`,
    method: 'post',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 批量修改api状态 */
export function batchUpdateApiStatus(data) {
  return axios({
    url: `/base/api/batch/update/status`,
    method: 'post',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 批量修改api公开状态 */
export function batchUpdateApiOpen(data) {
  return axios({
    url: `/base/api/batch/update/open`,
    method: 'post',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 批量修改api身份认证 */
export function batchUpdateApiAuth(data) {
  return axios({
    url: `/base/api/batch/update/auth`,
    method: 'post',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 获取scope列表 */
export function getScopeListApi() {
  return axios({
    url: ``,
    method: 'get',
  })
}

export const apiListManageApi = {
  getApiInfoList,
  getGatewayServiceList,
  addApi,
  deleteApi,
  updateApi,
  batchDeleteApi,
  batchUpdateApiStatus,
  batchUpdateApiOpen,
  batchUpdateApiAuth,
  getScopeListApi,
  // getJobList,
  // deleteJob,
  // stopJob,
  // jobLog,
}
