import CryptoJS from 'crypto-js'

// 生成随机字符串作为 code_verifier
export function generateRandomString(length) {
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let randomString = ''
  for (let i = 0; i < length; i++) {
    randomString += charset.charAt(Math.floor(Math.random() * charset.length))
  }
  return randomString
}

// 计算 PKCE code_challenge
export async function sha256(plain) {
  const hash = await CryptoJS.SHA256(plain)
  const base64String = hash.toString(CryptoJS.enc.Base64)
  console.log(base64String, 'base64String')
  return base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

// url: 重定向路径 一般为网站的登录页 tenant_id：租户id
export async function gotoAuth(url, tenant_id) {
  // 生成随机字符串作为 code_verifier
  const codeVerifier = generateRandomString(64)
  localStorage.setItem('codeVerifier', codeVerifier)
  // 计算 code_challenge
  const codeChallenge = await sha256(codeVerifier)
  console.log(codeChallenge, 'codecha')
  // 构建授权 URL
  const authEndpoint = process.env.VUE_APP_AUTH_API + '/oauth2/authorize'
  const clientId = 'pub'
  const redirectUri = url // 重定向路径
  const responseType = 'code'
  const state = generateRandomString(16)
  // org 选择租户
  // const authUrl = `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&state=${state}&code_challenge=${codeChallenge}&scene=org&code_challenge_method=S256&scope=openid%20profile%20email%20phone`;
  const authUrl = tenant_id
    ? `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&state=${state}&code_challenge=${codeChallenge}&scene=org&tenant_id=${tenant_id}&code_challenge_method=S256&scope=openid%20profile%20email%20phone`
    : `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&state=${state}&code_challenge=${codeChallenge}&scene=org&code_challenge_method=S256&scope=openid%20profile%20email%20phone`
  localStorage.setItem('state', state)
  localStorage.setItem('fromUrl', url)
  // 存储跳转路径（account网站，其他网站需要改变）
  if (url.includes('LoginForm') && url.includes('8081')) {
    localStorage.setItem(
      'fromUrl',
      'http://127.0.0.1:8081/layout/tenantManage/tenantList'
    )
  } else {
    localStorage.setItem(
      'fromUrl',
      process.env.VUE_APP_API_OPN_URL + '/layout/tenantManage/tenantList'
    )
  }
  // if (tenant_id && url.includes('8080')) {
  //   localStorage.setItem(
  //     'fromUrl',
  //     'http://127.0.0.1:8080'+`/companyCenter/${tenant_id}`
  //   )
  // }else if(tenant_id){
  //   localStorage.setItem(
  //     'fromUrl',
  //     process.env.VUE_APP_BASE_ACCOUNT_URL+`/companyCenter/${tenant_id}`
  //   )
  // }
  console.log(authUrl, 'authUrl')
  // 重定向到授权 URL
  window.location.replace(authUrl)
}

export async function getToken() {
  console.log(window.location.origin + window.location.pathname, 'fuck', window)
  // 计算 code_challenge
  const clientId = 'pub'
  // const redirectUri = 'http://127.0.0.1:8080/login/oauth2/code/cz'
  const redirectUri = window.location.origin + window.location.pathname
  const queryParams = new URLSearchParams(window.location.search)
  // 获取授权码
  const code = queryParams.get('code')
  console.log(code, 'code')
  // 构建令牌请求
  const tokenEndpoint = process.env.VUE_APP_AUTH_API + '/oauth2/token'
  const grantType = 'authorization_code'
  const tokenRequest = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      grant_type: grantType,
      code: code,
      redirect_uri: redirectUri,
      // scope: 'openid profile',
      client_id: clientId,
      state: 888,
      code_verifier: localStorage.getItem('codeVerifier'),
    }),
  }
  console.log(tokenRequest)
  // 发送令牌请求
  const tokenResponse = await fetch(tokenEndpoint, tokenRequest).then(
    (response) => response.json()
  )
  console.log(tokenResponse, 'tokenResponse')
  if (tokenResponse.code == 0) {
    localStorage.setItem('access_token', tokenResponse.data.access_token)
    localStorage.setItem('id_token', tokenResponse.data.id_token)
  }
  const fromUrl = localStorage.getItem('fromUrl')
  if (!fromUrl) {
    window.open(`http://127.0.0.1:8081/layout/tenantManage/tenantList`, '_self')
  } else {
    window.open(fromUrl, '_self')
  }
}
