/*
 * @Author: XSJ 13592841305@163.com
 * @Date: 2023-06-26 16:15:27
 * @LastEditors: XSJ 13592841305@163.com
 * @LastEditTime: 2023-10-12 14:25:29
 * @FilePath: \cz-operation\src\api\systemServe\systemManageApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { axios } from '@/utils/request'
import { stringify } from 'qs'

/* 重置应用秘钥 */
export function reset(appId) {
  return axios({
    url: `/base/app/reset`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: stringify({
      appId,
    }),
  })
}

/* ----------------------- */
/* 应用列表、（客户端列表） */
export function getClientList(params) {
  return axios({
    url: `/auth/clients`,
    method: 'get',
    params,
  })
}

/* 删除应用（删除客户端） */
export function deleteClient(id) {
  return axios({
    url: `/auth/clients/${id}`,
    method: 'get',
  })
}

/* 更新应用（更新客户端） */
export function updateClient(id, data) {
  return axios({
    url: `/auth/clients/${id}`,
    method: 'put',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

/* 添加应用（注册新的客户端） */
export function addClient(data) {
  return axios({
    url: `/auth/clients`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const systemManageApi = {
  reset,
  getClientList,
  deleteClient,
  updateClient,
  addClient,
}
