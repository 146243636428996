/*
 * @Author: XSJ 13592841305@163.com
 * @Date: 2023-08-04 11:24:26
 * @LastEditors: XSJ 13592841305@163.com
 * @LastEditTime: 2023-10-31 11:29:51
 * @FilePath: \cz-operation\src\api\tenantManageServe\tenantListApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { axios } from '@/utils/request'
import { stringify } from 'qs'

/* 获取行业信息列表 */
export function getIndustryList() {
  return axios({
    url: `/dict/dictData/listnopage`,
    method: 'get',
    params: {
      dictType: 'crm_industry',
    },
  })
}

/* 分页获取租户下用户列表 */
export function getUserListByTid(tenantId, params) {
  return axios({
    url: `/platform/managements/tenants/${tenantId}/member`,
    method: 'get',
    params,
  })
}

/* 迁移租户管理员 */
export function changeManagerOfTenant(params) {
  return axios({
    url: `/platform/managements/tenants/admin`,
    method: 'put',
    params,
  })
}

/* 获取租户高级业务订阅信息列表 */
export function getTenantSubscriptionScheme(params) {
  return axios({
    url: `/market/planRegister/renter/list`,
    method: 'get',
    params,
  })
}

/* 批量为租户添加资源数量 */
export function batchAddSourceOfTenant(data) {
  return axios({
    url: `/warestore/v1/resource/subscriptions`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
}

/* 获取租户资源订阅记录概况 */
export function getResourceRecord(tid) {
  return axios({
    url: `/warestore/v1/resource/subscriptions/overview`,
    method: 'get',
    params: {
      'tenant-id': tid,
    },
  })
}

/* -------------- */
/* 获取租户信息列表(新架构) */
export function _getTenantInfoList(params) {
  return axios({
    url: `platform/managements/tenants`,
    method: 'get',
    params,
  })
}

export function _getTenantBusinessInformation(tenantId) {
  return axios({
    url: `platform/managements/tenants/${tenantId}/organization-information`,
    method: 'get',
  })
}

/* 标记跟进租户 */
export function trackTenant(tenantId) {
  return axios({
    url: `/platform/managements/tenants/${tenantId}/track`,
    method: 'post',
  })
}

/* 取消跟进 */
export function unTrackTenant(tenantId) {
  return axios({
    url: `/platform/managements/tenants/${tenantId}/track`,
    method: 'delete',
  })
}

/* 更新租户组织认证信息 */
export function updateTenantInformation(tenantId, data) {
  return axios({
    url: `/platform/managements/tenants/${tenantId}/organization-information`,
    method: 'put',
    data,
  })
}

/* 审核租户认证信息 */
export function auditTenant(data) {
  return axios({
    url: `/platform/managements/tenants/audit`,
    method: 'post',
    data,
  })
}

/* 启用租户 */
export function enableTenant(tenantId) {
  return axios({
    url: `/platform/managements/tenants/${tenantId}/enable`,
    method: 'post',
  })
}

/* 禁用租户 */
export function disableTenant(tenantId) {
  return axios({
    url: `/platform/managements/tenants/${tenantId}/disable`,
    method: 'post',
  })
}

export const tenantListApi = {
  getIndustryList,
  getUserListByTid,
  changeManagerOfTenant,
  getTenantSubscriptionScheme,
  batchAddSourceOfTenant,
  getResourceRecord,
  /* ------------ */
  _getTenantInfoList,
  _getTenantBusinessInformation,
  trackTenant,
  unTrackTenant,
  updateTenantInformation,
  auditTenant,
  enableTenant,
  disableTenant,
}
