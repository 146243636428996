import { axios } from '@/utils/request'
import { stringify } from 'qs'

/* 创建、登记应用 */
export function createdApplication(data) {
  return axios({
    url: `/warestore/v1/menus/applications`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
}

/* 编辑应用 */
export function editApplication(applicationId, data) {
  return axios({
    url: `/warestore/v1/menus/applications/${applicationId}`,
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
}

/* 删除应用 */
export function delApplication(applicationId) {
  return axios({
    url: `/warestore/v1/menus/applications/${applicationId}`,
    method: 'delete',
  })
}

/* 查询单个应用信息 */
export function searchApplication(applicationId) {
  return axios({
    url: `/warestore/v1/menus/applications/${applicationId}`,
    method: 'get',
  })
}

/* 获取应用列表 */
export function getRegisterApplicationList(params = {}) {
  return axios({
    url: `/warestore/v1/menus/applications`,
    method: 'get',
    params,
  })
}

/* ------------------------------------------------- */

/* 获取解决方案列表 */
export function getSolutionList() {
  return axios({
    url: `/warestore/v1/menus/solutions`,
    method: 'get',
  })
}

/*  创建解决方案*/
export function cratedSolution(data) {
  return axios({
    url: `/warestore/v1/menus/solutions`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
}

/* 查询单个解决方案 */
export function searchSolution(solutionId) {
  return axios({
    url: `/warestore/v1/menus/solutions/${solutionId}`,
    method: 'get',
  })
}

/* 编辑解决方案 */
export function editSolution(solutionId, data) {
  return axios({
    url: `/warestore/v1/menus/solutions/${solutionId}`,
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
}

/* 删除解决方案 */
export function delSolution(solutionId) {
  return axios({
    url: `/warestore/v1/menus/solutions/${solutionId}`,
    method: 'delete',
  })
}

/* 应用解决方案状态开关，权限组开关（解决方案启用） */
export function permissionEnable(code) {
  return axios({
    url: `/platform/managements/permission-groups/code/${code}/enable`,
    method: 'post',
  })
}
/* 应用解决方案状态开关，权限组开关（解决方案禁用） */
export function permissionDisable(code) {
  return axios({
    url: `/platform/managements/permission-groups/code/${code}/disable`,
    method: 'post',
  })
}
/* 应用状态开关，权限开关（应用启用） */
export function appPermissionEnable(code) {
  return axios({
    url: `/platform/managements/permission/code/${code}/enable`,
    method: 'post',
  })
}
/* 应用状态开关，权限开关（应用禁用） */
export function appPermissionDisable(code) {
  return axios({
    url: `/platform/managements/permission/code/${code}/disable`,
    method: 'post',
  })
}

export const appRegisterApi = {
  createdApplication,
  editApplication,
  delApplication,
  searchApplication,
  getRegisterApplicationList,
  getSolutionList,
  cratedSolution,
  searchSolution,
  editSolution,
  delSolution,
  permissionEnable,
  permissionDisable,
  appPermissionEnable,
  appPermissionDisable,
}
