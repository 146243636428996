import { axios } from '@/utils/request'
import { stringify } from 'qs'

/* 获取微信特约商户列表 */
export function getWeChatMallList(params) {
  return axios({
    url: `/market/payTypeInfo/list`,
    method: 'get',
    params,
  })
}

/* 新增微信特约商户 */
export function addWechatMall(data) {
  return axios({
    url: `/market/payTypeInfo/manage/add`,
    method: 'post',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 编辑、更新微信特约商户 */
export function updateWechatMall(data) {
  return axios({
    url: `/market/payTypeInfo/manage/update`,
    method: 'post',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 删除微信特约商户 */
export function deleteWechatMall(data) {
  return axios({
    url: `/market/payTypeInfo/remove`,
    method: 'post',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 锁定、解锁微信特约商户 */
export function lockWechatMall(params) {
  return axios({
    url: `/market/payTypeInfo/manage/locked/update`,
    method: 'get',
    params,
  })
}

export const wechatMallListApi = {
  getWeChatMallList,
  addWechatMall,
  updateWechatMall,
  deleteWechatMall,
  lockWechatMall,
}
