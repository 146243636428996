import { axios } from '@/utils/request'
import { stringify } from 'qs'

/* 广告位列表 */
export function advertisementPositionList(data) {
  return axios({
    url: `/ad/adSlot/list`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
}

/* 广告位创建 */
export function advertisementPositionCreated(data) {
  return axios({
    url: `/ad/adSlot/create`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
}

/* 广告位删除 */
export function advertisementPositionDel(ids) {
  return axios({
    url: `/ad/adSlot/delete`,
    method: 'get',
    params: {
      ids,
    },
    // paramsSerializer序列化
    paramsSerializer: (params) => {
      return stringify(params, { indices: false })
      //  stringify({ids: [1,2,3]},{indices: false})
      //  形式： ids=1&ids=2&ids=3
    },
  })
}

/* 广告位修改 */
export function advertisementPositionUpdate(data) {
  return axios({
    url: `/ad/adSlot/update`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
}

/* 广告位开关 */
export function advertisementPositionOnOff(enable, ids) {
  return axios({
    url: `/ad/adSlot/onOff`,
    method: 'get',
    params: {
      enable,
      ids,
    },
    // paramsSerializer序列化
    paramsSerializer: (params) => {
      return stringify(params, { indices: false })
      //  stringify({ids: [1,2,3]},{indices: false})
      //  形式： ids=1&ids=2&ids=3
    },
  })
}

/* -------------------------------------- */

/* 频道列表 */
export function channelList() {
  return axios({
    url: `/ad/channel/list`,
    method: 'get',
  })
}

/* 频道创建 */
export function channelCreated(data) {
  return axios({
    url: `/ad/channel/create`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
}

/* 频道删除 */
export function channelDel(ids) {
  return axios({
    url: `/ad/channel/delete`,
    method: 'get',
    params: {
      ids,
    },
    // paramsSerializer序列化
    paramsSerializer: (params) => {
      return stringify(params, { indices: false })
      //  stringify({ids: [1,2,3]},{indices: false})
      //  形式： ids=1&ids=2&ids=3
    },
  })
}

/* 频道修改 */
export function channelUpdate(data) {
  return axios({
    url: `/ad/channel/update`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
}

/* ----------------------------------------- */

/* 广告列表(频道) */
export function advertisementList(ids, data) {
  return axios({
    url: `/ad/ad/list`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ids,
    },
    data,
    // paramsSerializer序列化
    paramsSerializer: (params) => {
      return stringify(params, { indices: false })
      //  stringify({ids: [1,2,3]},{indices: false})
      //  形式： ids=1&ids=2&ids=3
    },
  })
}

/* 广告列表（全查） */
export function advertisementListAll() {
  return axios({
    url: `/ad/ad/findAll`,
    method: 'get',
  })
}

/* 广告创建 */
export function advertisementCreated(channelIds, data) {
  return axios({
    url: `/ad/ad/create`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    params: {
      channelIds,
    },
    // paramsSerializer序列化
    paramsSerializer: (params) => {
      return stringify(params, { indices: false })
      //  stringify({ids: [1,2,3]},{indices: false})
      //  形式： ids=1&ids=2&ids=3
    },
  })
}

/* 广告删除 */
export function advertisementDel(ids, channelId) {
  return axios({
    url: `/ad/ad/delete`,
    method: 'get',
    params: {
      ids,
      channelId,
    },
    // paramsSerializer序列化
    paramsSerializer: (params) => {
      return stringify(params, { indices: false })
      //  stringify({ids: [1,2,3]},{indices: false})
      //  形式： ids=1&ids=2&ids=3
    },
  })
}

/* 广告审核 */
export function advertisementAudit(ids, status) {
  return axios({
    url: `/ad/ad/audit`,
    method: 'get',
    params: {
      ids,
      status,
    },
    // paramsSerializer序列化
    paramsSerializer: (params) => {
      return stringify(params, { indices: false })
      //  stringify({ids: [1,2,3]},{indices: false})
      //  形式： ids=1&ids=2&ids=3
    },
  })
}
/* 广告开关 */
export function advertisementOnOff(ids, enable) {
  return axios({
    url: `/ad/ad/onOff`,
    method: 'get',
    params: {
      ids,
      enable,
    },
    // paramsSerializer序列化
    paramsSerializer: (params) => {
      return stringify(params, { indices: false })
      //  stringify({ids: [1,2,3]},{indices: false})
      //  形式： ids=1&ids=2&ids=3
    },
  })
}

/* 广告修改 */
export function advertisementUpdate(data) {
  return axios({
    url: `/ad/ad/update`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
}

/* 广告批量迁移、移动 */
export function advertisementMove(params) {
  return axios({
    url: `/ad/ad/move`,
    method: 'get',
    params,
    // paramsSerializer序列化
    paramsSerializer: (params) => {
      return stringify(params, { indices: false })
      //  stringify({ids: [1,2,3]},{indices: false})
      //  形式： ids=1&ids=2&ids=3
    },
  })
}

export const advertisementApi = {
  advertisementPositionList,
  advertisementPositionCreated,
  advertisementPositionDel,
  advertisementPositionUpdate,
  advertisementPositionOnOff,
  channelList,
  channelCreated,
  channelDel,
  channelUpdate,
  advertisementList,
  advertisementListAll,
  advertisementCreated,
  advertisementDel,
  advertisementAudit,
  advertisementUpdate,
  advertisementMove,
  advertisementOnOff,
}
