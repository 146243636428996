/*
 * @Author: XSJ 13592841305@163.com
 * @Date: 2022-07-12 09:29:31
 * @LastEditors: XSJ 13592841305@163.com
 * @LastEditTime: 2023-10-10 18:09:29
 * @FilePath: \cz-operation\src\api\loginServe\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { loginApi } from './loginApi.js'
// import { userInfoApi } from './userInfoApi.js'

export const loginServe = {
  ...loginApi,
  // ...userInfoApi
}
