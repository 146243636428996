import { axios } from '@/utils/request'

/* 获取消息推送场景列表 */
export function getNotificationSceneList(data) {
  return axios({
    url: ``,
    method: '',
  })
}

/* 获取业务消息推送-分组列表 */
export function getBusinessNotificationGroupList() {
  return axios({
    url: `/push/template-center/groups?tid=-1`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 添加分组 */
export function addBusinessNotificationGroup(data) {
  return axios({
    url: `/push/template-center/groups`,
    method: 'post',
    data: {
      ...data,
      tid: '-1',
    },
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 修改分组信息 */
export function updateBusinessNotificationGroup(groupId, data) {
  return axios({
    url: `/push/template-center/groups/${groupId}`,
    method: 'put',
    data,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 删除分组 */
export function deleteBusinessNotificationGroup(groupId) {
  return axios({
    url: `/push/template-center/groups/${groupId}`,
    method: 'delete',
    data: {
      groupId,
      tid: '-1',
    },
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 获取解决方案列表 */
export function planList() {
  return axios({
    url: `/market/appPlan/list`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 获取通知场景列表 */
export function getNotificationScene(groupId) {
  return axios({
    url: `/push/template-center/scenarios?groupIds=${groupId}&tid=-1`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 添加业务通知场景 */
export function addNotificationScene(data) {
  return axios({
    url: `/push/template-center/scenarios`,
    method: 'post',
    data: {
      ...data,
      tid: '-1',
    },
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 删除业务通知场景 */
export function deleteNotificationScene(sceneId) {
  return axios({
    url: `/push/template-center/scenarios/${sceneId}`,
    method: 'delete',
    data: {
      scenarioId: sceneId,
      tid: '-1',
    },
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 根据业务场景获取消息模板 */
export function getTemplateByScenarioId(scenarioId) {
  return axios({
    url: `/push/template-center/templates?scenarioId=${scenarioId}&tid=-1`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 更新消息模板（渠道） */
export function updateTemplate(templateId, data) {
  return axios({
    url: `/push/template-center/templates/${templateId}`,
    method: 'put',
    data: {
      ...data,
      tid: '-1',
    },
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 添加消息模板（渠道） */
export function addTemplateChannel(data) {
  return axios({
    url: `/push/template-center/templates`,
    method: 'post',
    data: {
      ...data,
      tid: '-1',
    },
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 根据业务场景查询变量列表 */
export function getVariableByScenarioId(scenarioId) {
  return axios({
    url: `/push/template-center/variables?scenarioId=${scenarioId}&tid=-1`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 更新业务场景 */
export function updateScene(scenarioId, data) {
  return axios({
    url: `/push/template-center/scenarios/${scenarioId}?tid=-1`,
    method: 'put',
    data: {
      ...data,
      tid: '-1',
    },
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 添加业务场景变量 */
export function addVariableOfScene(data) {
  return axios({
    url: `/push/template-center/variables`,
    method: 'post',
    data: {
      ...data,
      tid: '-1',
    },
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 删除业务场景变量 */
export function deleteVariableOfScene(variableId) {
  return axios({
    url: `/push/template-center/variables/${variableId}`,
    method: 'delete',
    data: {
      variableId,
      tid: '-1',
    },
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 获取客制化配置列表（个性化配置） */
export function getCustomizationList(scenarioId) {
  return axios({
    url: `/push/customization-center/customizations`,
    method: 'get',
    params: {
      // scenarioId,
      tid: '-1',
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/*  更新客制化配置（个性化） */
export function updateCustomization(data) {
  let { customizationId } = data
  return axios({
    url: `/push/customization-center/customizations/${customizationId}`,
    method: 'put',
    data: {
      ...data,
      tid: '-1',
    },
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 删除客制化配置 */
export function deleteCustomization(customizationId) {
  return axios({
    url: `/push/customization-center/customizations/${customizationId}`,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 添加客制化配置 */
export function addCustomization(data) {
  return axios({
    url: `/push/customization-center/customizations`,
    method: 'post',
    data: {
      ...data,
      tid: '-1',
    },
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 获取场景分配所用的租户信息列表 */
export function getAllTenantList() {
  return axios({
    url: `/base/renter/renters`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 获取场景下已分配的租户信息列表 */
export function getAlreadyAllocationTenantList(scenarioIds) {
  return axios({
    url: `/push/customization-center/permissions?scenarioIds=${scenarioIds}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 场景分配给租户 */
export function sceneAllocationToTenant(data) {
  return axios({
    url: `/push/customization-center/permissions`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

/* 场景取消分配给租户 */
export function cancelAllocationScene(permissionIds) {
  return axios({
    url: `/push/customization-center/permissions`,
    method: 'delete',
    data: {
      permissionIds,
    },
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
}

export const notificationApi = {
  getNotificationSceneList,
  getBusinessNotificationGroupList,
  addBusinessNotificationGroup,
  updateBusinessNotificationGroup,
  planList,
  deleteBusinessNotificationGroup,
  getNotificationScene,
  addNotificationScene,
  deleteNotificationScene,
  getTemplateByScenarioId,
  getVariableByScenarioId,
  addVariableOfScene,
  deleteVariableOfScene,
  updateScene,
  updateTemplate,
  getCustomizationList,
  updateCustomization,
  deleteCustomization,
  addCustomization,
  getAllTenantList,
  getAlreadyAllocationTenantList,
  sceneAllocationToTenant,
  cancelAllocationScene,
  addTemplateChannel,
}
