/*
 * @Author: 徐少杰 xushaojie@yihuosoft.com
 * @Date: 2024-05-31 10:08:53
 * @LastEditors: 徐少杰 xushaojie@yihuosoft.com
 * @LastEditTime: 2024-06-17 11:49:06
 * @FilePath: \cz-operation-web\src\api\dataManagementServe\dataTask.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { axios } from '@/utils/request'
import { stringify } from 'qs'

/* 获取数据中心任务调度列表 */
export function getJobList(params) {
  return axios({
    url: `/dataflow/managements/jobs`,
    // url: `/dataflow/jobs`,
    method: 'get',
    params,
  })
}

/* 删除数组中心任务调度的任务 */
export function deleteJob(jobId) {
  return axios({
    url: `/dataflow/managements/jobs/${jobId}`,
    method: 'delete',
  })
}

/* 更新任务调度的任务（停止任务） */
export function stopJob(data) {
  return axios({
    url: `/dataflow/managements/jobs/${data.id}`,
    method: 'put',
    data,
  })
}

// 根据id获取数据调度
export function getTaskInfo(id) {
  return axios({
    url: `/dataflow/jobs/${id}`,
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    method: 'get',
  })
}

/* 查看任务日志 */
export function jobLog(jobId) {
  return axios({
    url: `/dataflow/managements/jobs/${jobId}/logs`,
    method: 'get',
  })
}

/* 获取数据处理列表 */
export function getDataProcessingList(params) {
  return axios({
    url: '/dataflow/managements/flows',
    method: 'GET',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    params,
  })
}

// 分页获取任务调度日志
export function getTaskLogList(params, jobId) {
  return axios({
    url: `/dataflow/managements/jobs/${jobId}/logs`,
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    method: 'get',
    params,
  })
}

// 获取数据处理日志
export function getDataProcessingLogList(params, flowId) {
  return axios({
    url: `/dataflow/managements/flows/${flowId}/logs`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    params,
  })
}

// 获取数据处理(flowId)
export function getDataProcessingInfo(id) {
  return axios({
    url: `/dataflow/managements/flows/${id}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    // params,
  })
}

export const dataTaskAndProcessingApi = {
  getJobList,
  jobLog,
  getDataProcessingList,
  getTaskInfo,
  getTaskLogList,
  getDataProcessingLogList,
  getDataProcessingInfo,
}
