/* vuex局部模块 */
export default {
  namespaced: true, /* 开启命名空间 */
  state() {
    return {
      name: 'xsj',
      age: 23,
      address: '深圳市南山区粤海街道'
    }
  },
  getters: {
    getName(state, payload) {
      return `《${state.name}》`
    }
  },
  mutations: {

  },
  actions: {

  }

}
