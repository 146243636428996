import moment from 'moment'

/**
 * 获取字符串长度，英文字符 长度1，中文字符长度2
 * @param {*} str
 */
export const getStrFullLength = (str = '') =>
  str.split('').reduce((pre, cur) => {
    const charCode = cur.charCodeAt(0)
    if (charCode >= 0 && charCode <= 128) return pre + 1
    return pre + 2
  }, 0)

/**
 * 截取字符串，根据 maxLength 截取后返回
 * @param {*} str
 * @param {*} maxLength
 */
export const cutStrByFullLength = (str = '', maxLength) => {
  let showLength = 0
  return str.split('').reduce((pre, cur) => {
    const charCode = cur.charCodeAt(0)
    if (charCode >= 0 && charCode <= 128) {
      showLength += 1
    } else {
      showLength += 2
    }
    if (showLength <= maxLength) return pre + cur
    return pre
  }, '')
}

export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9
    ? '早上好'
    : hour <= 11
    ? '上午好'
    : hour <= 13
    ? '中午好'
    : hour < 20
    ? '下午好'
    : '晚上好'
}

export function welcome() {
  const arr = [
    '休息一会儿吧',
    '准备吃什么呢?',
    '要不要打一把 DOTA',
    '我猜你可能累了',
  ]
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader(callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    (event) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}

/**
 * 将扁平化数据组装成树状
 * @param list 目标数组对象
 * @param id   id字段
 * @param parentId 指向父级目录id的字段
 * @param children 父级目录用来存放子项的字段
 */

export function formatTreeData(
  list,
  id = 'id',
  parentId = 'parentId',
  children = 'children'
) {
  let current, pointer, target
  for (let i = 0; i < list.length; i++) {
    current = list[i]
    pointer = current[parentId]
    target = list.find((item) => item[id] == pointer)
    if (target) {
      if (!target[children]) target[children] = []
      target[children].push(current)
    }
  }
}

/**
 * @description:
 * @param {string}
 * @return {fileName,type}
 */
export function getFileName(str) {
  if (str === undefined) return {}
  const index = str.lastIndexOf('.')
  return {
    fileName: str.slice(0, index),
    type: str.slice(index + 1),
  }
}
/**
 * @description: 防抖 非立即执行
 * @param {type}
 * @return:
 */
export function debounce(func, wait) {
  return function () {
    const context = this // 这边的 this 指向谁?
    const args = arguments // arguments中存着e
    const callNow = !window.timertimer
    if (callNow) func.apply(context, args)
    if (window.timertimer) {
      clearTimeout(window.timertimer)
    }
    window.timertimer = setTimeout(() => {
      window.timertimer = null
    }, wait)
  }
}
/**
 * @description: 节流 非立即执行
 * @param {type}
 * @return:
 */
export function throttle(func, wait) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = null
        func.apply(context, args)
      }, wait)
    }
  }
}

/**
 * @desc  函数防抖---“立即执行版本” 和 “非立即执行版本” 的组合版本
 * @param  func 需要执行的函数
 * @param  wait 延迟执行时间（毫秒）
 * @param  immediate---true 表立即执行，false 表非立即执行
 **/
/*
const debounce_merge_vue  = debounce_merge(func, wait, immediate = false)
*/

export function debounce_merge(func, wait, immediate = false) {
  let timer
  return function () {
    const context = this
    const args = arguments

    if (timer) clearTimeout(timer)
    if (immediate) {
      var callNow = !timer
      timer = setTimeout(() => {
        timer = null
      }, wait)
      if (callNow) func.apply(context, args)
    } else {
      timer = setTimeout(function () {
        func.apply(context, args)
      }, wait)
    }
  }
}
//  节流 的合并版  isImmediate控制是否立即执行
export function throttle_merge(fn, wait = 500, isImmediate = false) {
  var flag = true
  var timer = null
  if (isImmediate) {
    return function () {
      if (flag) {
        fn.apply(this, arguments)
        flag = false
        timer = setTimeout(() => {
          flag = true
        }, wait)
      }
    }
  }
  return function () {
    if (flag == true) {
      flag = false
      var timer = setTimeout(() => {
        fn.apply(this, arguments)
        flag = true
      }, wait)
    }
  }
}

//  生成时间序列号  => 2021 01 01
export const getTimeId = (time) => {
  const year = time.getFullYear() // 获取完整的年份(4位,1970)
  const month = time.getMonth() // 获取月份(0-11,0代表1月,用的时候记得加上1)
  const date = time.getDate() // 获取日(1-31)

  return `${year}${month + 1 >= 10 ? month + 1 : '0' + (month + 1)}${
    date >= 10 ? date : '0' + date
  }`
}

//  生成时间序列号  => 2021 01 01
export const getTimeDate = (time) => {
  const year = time.getFullYear() // 获取完整的年份(4位,1970)
  const month = time.getMonth() // 获取月份(0-11,0代表1月,用的时候记得加上1)
  const date = time.getDate() // 获取日(1-31)
  const hour = time.getHours() // 获取日(1-31)
  const minute = time.getMinutes() // 获取日(1-31)
  const second = time.getSeconds() // 获取日(1-31)

  return `${year}年${month + 1 >= 10 ? month + 1 : '0' + (month + 1)}月${
    date >= 10 ? date : '0' + date
  }日${hour}时${minute}分${second}秒`
}

//  生成时间序列号  => 2021 01 01
export const getFullTime = (time) => {
  const year = time.getFullYear() // 获取完整的年份(4位,1970)
  const month = time.getMonth() // 获取月份(0-11,0代表1月,用的时候记得加上1)
  const date = time.getDate() // 获取日(1-31)
  const hour = time.getHours() // 获取日(1-31)
  const minute = time.getMinutes() // 获取日(1-31)
  const second = time.getSeconds() // 获取日(1-31)

  return `${year}${month + 1 >= 10 ? month + 1 : '0' + (month + 1)}${
    date >= 10 ? date : '0' + date
  }${hour}${minute}${second}`
}
/**
 * @params:{}
 * @description: 时间规则
 * @Date: 2020-05-08 18:01:43
 */
export const getTime = (time) => {
  // console.log('time',time)
  // 获取当前年份
  const year = moment(new Date()).year()
  // 创建任务时间年份
  const createYear = moment(time).year()
  // 相差年份
  const yearNum = year - createYear
  const currentdate = moment(time).format('YYYY-MM-DD')
  // 创建任务当天24时的时间戳
  const currentDate = new Date(`${currentdate} 24:00:00`).getTime()
  // 当前时间戳
  const currentTime = new Date().getTime()
  // 创建时间戳
  const createTime = new Date(time).getTime()
  // 当前时间戳-创建时间戳 = 已过去的时间戳
  const num = currentTime - createTime
  /*
            是当前时间 不是创建任务时间
            当前时间-创建任务当天时间 > 24*60*1000 = 前天
            当前时间-创建任务当天时间 > 0 = 昨天
            当前时间-创建任务当天时间 < 0 = 今天
        */
  const numTime = currentTime - currentDate
  if (num < 60 * 60 * 1000) {
    return moment(time).fromNow()
  } else if (numTime < 0) {
    // 当前时间-创建任务当天时间 < 0= 今天
    return moment(createTime).format('HH:mm')
  } else if (numTime > 0 && numTime < 24 * 60 * 60 * 1000) {
    // 当前时间-创建任务当天时间 > 0 = 昨天
    return `昨天${moment(createTime).format('HH:mm')}`
  } else if (numTime > 24 * 60 * 60 * 1000 && numTime < 48 * 60 * 60 * 1000) {
    // 当前时间-创建任务当天时间 > 24*60*1000 = 前天
    return `前天${moment(createTime).format('HH:mm')}`
  } else if (numTime > 48 * 60 * 60 * 1000 && yearNum == 0) {
    // 当前时间-创建任务当天时间 > 24*60*1000 并且 在今年
    return `${moment(createTime).format('MM月DD日 HH:mm')}`
  } else if (yearNum > 0) {
    // 超过今年
    return `${moment(createTime).format('YYYY年MM月DD日 HH:mm')}`
  }
}

export function moneyFormat(value) {
  if (!value) return '0.00'
  var intPart = Number(value) - (Number(value) % 1) // 获取整数部分（这里是windy93的方法）
  var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  var floatPart = '.00' // 预定义小数部分
  var value2Array = value.toString().split('.')
  // =2表示数据有小数位
  if (value2Array.length == 2) {
    floatPart = value2Array[1].toString() // 拿到小数部分

    if (floatPart.length == 1) {
      // 补0,实际上用不着
      return intPartFormat + '.' + floatPart + '0'
    } else {
      return intPartFormat + '.' + floatPart
    }
  } else {
    /*
    if (isNaN(intPartFormat)) { intPartFormat = '0' }
*/
    return intPartFormat + floatPart
  }
}

export function moneyFmt(money, pre = 2) {
  // 修改了moneyFormat产生的错误，需要配合moneyFormat来使用， 默认两位小数
  let pow = Math.pow(10, pre)
  let fMoney = Math.round(Number(money) * pow) / pow
  return fMoney.toFixed(pre)
}

export const $c = {
  // 除法函数，用来得到精确的除法结果
  // 说明：javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
  // 调用：$h.Div(arg1,arg2)
  // 返回值：arg1除以arg2的精确结果
  Div: function (arg1, arg2) {
    arg1 = parseFloat(arg1)
    arg2 = parseFloat(arg2)
    var t1 = 0
    var t2 = 0
    var r1
    var r2
    try {
      t1 = arg1.toString().split('.')[1].length
    } catch (e) {}
    try {
      t2 = arg2.toString().split('.')[1].length
    } catch (e) {}
    r1 = Number(arg1.toString().replace('.', ''))
    r2 = Number(arg2.toString().replace('.', ''))
    return this.Mul(r1 / r2, Math.pow(10, t2 - t1))
  },
  // 加法函数，用来得到精确的加法结果
  // 说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
  // 调用：$h.Add(arg1,arg2)
  // 返回值：arg1加上arg2的精确结果
  Add: function (arg1, arg2) {
    arg2 = parseFloat(arg2)
    var r1, r2, m
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    m = Math.pow(100, Math.max(r1, r2))
    return (this.Mul(arg1, m) + this.Mul(arg2, m)) / m
  },
  // 减法函数，用来得到精确的减法结果
  // 说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的减法结果。
  // 调用：$h.Sub(arg1,arg2)
  // 返回值：arg1减去arg2的精确结果
  Sub: function (arg1, arg2) {
    arg1 = parseFloat(arg1)
    arg2 = parseFloat(arg2)
    var r1, r2, m, n
    try {
      r1 = arg1.toString().split('.')[1].length
    } catch (e) {
      r1 = 0
    }
    try {
      r2 = arg2.toString().split('.')[1].length
    } catch (e) {
      r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2))
    // 动态控制精度长度
    n = r1 >= r2 ? r1 : r2
    return ((this.Mul(arg1, m) - this.Mul(arg2, m)) / m).toFixed(n)
  },
  // 乘法函数，用来得到精确的乘法结果
  // 说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
  // 调用：$h.Mul(arg1,arg2)
  // 返回值：arg1乘以arg2的精确结果
  Mul: function (arg1, arg2) {
    arg1 = parseFloat(arg1)
    arg2 = parseFloat(arg2)
    var m = 0
    var s1 = arg1.toString()
    var s2 = arg2.toString()
    try {
      m += s1.split('.')[1].length
    } catch (e) {}
    try {
      m += s2.split('.')[1].length
    } catch (e) {}
    return (
      (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
      Math.pow(10, m)
    )
  },
}

export const calcDateTime = (start, end = null) => {
  let result = ''
  if (!end) {
    end = new Date().toString()
  } else {
    end = end.replace(/-/g, '/')
  }
  start = start.replace(/-/g, '/')
  const startTime = new Date(start).getTime()
  const endTime = new Date(end).getTime()
  const durationTime = endTime - startTime
  let days = Math.floor(durationTime / (24 * 3600 * 1000))
  if (days >= 1) {
    if (days >= 30) {
      let month = Math.floor(days / 30)
      if (month >= 12) {
        let year = Math.floor(month / 12)
        result = `${year}年`
      } else {
        result = `${month}个月`
      }
    } else {
      result = `${days}天`
    }
  } else {
    let diff = durationTime % (24 * 3600 * 1000)
    let hours = Math.floor(diff / (3600 * 1000))
    if (hours == 0) {
      let minutes = Math.floor(diff / (60 * 1000))
      if (minutes == 0) {
        let seconds = Math.floor(diff / 1000)
        result = `${seconds}秒`
      } else {
        let seconds = diff % (60 * 1000)
        result = `${minutes}分钟${seconds}秒`
      }
    } else {
      result = `${hours}小时`
    }
  }
  return result
}
