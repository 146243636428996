/*
 * @Author: XSJ 13592841305@163.com
 * @Date: 2022-11-25 14:32:51
 * @LastEditors: XSJ 13592841305@163.com
 * @LastEditTime: 2023-08-24 15:43:06
 * @FilePath: \cz-operation\src\api\resourceServe\resourceApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { axios } from '@/utils/request'

/* 获取资源初始化配置skucode */
export function getResourceInitial() {
  return axios({
    url: `/warestore/v1/resource/bindings/initial-resource-skucode`,
    method: 'get',
  })
}

/* 更新资源初始化配置skucode */
export function _modifyResourceInitial(skucode) {
  return axios({
    url: `/warestore/v1/resource/bindings/initial-resource-skucode/${skucode}`,
    method: 'put',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/* 获取资源使用列表数据 */
export function getResourceUseByTenant(params) {
  return axios({
    url: `/market/opnResource/list`,
    method: 'get',
    params: {
      ...params,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/* 获取对应租户的对应类型资源历史数据 */
export function getHistoricalDataByTenant(tid, resourceType) {
  return axios({
    url: `/market/resource/list?tid=${tid}&resourcetype=${resourceType}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/* 租户对应类型资源大小追加 */
export function resourceCountAddByType(data) {
  return axios({
    url: `/market/resource/add`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export const resourceApi = {
  getResourceInitial,
  _modifyResourceInitial,
  getResourceUseByTenant,
  getHistoricalDataByTenant,
  resourceCountAddByType,
}
