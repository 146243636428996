/*
 * @Author: XSJ 13592841305@163.com
 * @Date: 2023-08-04 11:21:39
 * @LastEditors: XSJ 13592841305@163.com
 * @LastEditTime: 2023-08-09 14:14:24
 * @FilePath: \cz-operation\src\api\tenantManageServe\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { tenantListApi } from './tenantListApi'
import { wechatMallListApi } from './wechatMallListApi'

export const tenantManageServe = {
  ...tenantListApi,
  ...wechatMallListApi,
}
