/*
 * @Author: 徐少杰 xushaojie@yihuosoft.com
 * @Date: 2024-05-30 16:37:53
 * @LastEditors: 徐少杰 xushaojie@yihuosoft.com
 * @LastEditTime: 2024-05-30 16:43:19
 * @FilePath: \cz-operation-web\src\components\unifyAvatar\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import unifyAvatar from './unifyAvatar'
// const uAvatar = {
//   install: function (Vue) {
//     Vue.component('uAvatar', unifyAvatar)
//   },
// }
// export default uAvatar
// const uAvatar = {
//   install: function (Vue) {
//     Vue.component('uAvatar', unifyAvatar)
//   },
// }
export default unifyAvatar
