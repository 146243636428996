import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import routesConfig from '@/router/router.js'
import jwtDecode from 'jwt-decode'

Vue.use(VueRouter)

// 静态路由，主要是登录页面、404页等不要动态的路由
export const constantRoutes = routesConfig

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  routes: constantRoutes,
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

router.beforeEach(async (to, from, next) => {
  // console.log('111')

  if (localStorage.getItem('access_token')) {
    if (to.path === '/login') {
      next({
        path: '/layout',
      })
    } else if (to.path === '/other') {
      next()
    } else {
      // console.log(to)
      /* 判断是否系统管理员权限 */
      resolveToken()
        .then(() => {
          next()
        })
        .catch(() => {
          next({ path: '/other' })
        })
    }
  } else if (
    !localStorage.getItem('access_token') &&
    to.name == 'LoginForm' &&
    !to.query.code
  ) {
    console.log('AAA')
    let fromUrl = window.location.href.replace('other', 'layout')
    fromUrl = fromUrl.split('?')[0]
    store.dispatch('getToken', fromUrl)
  } else if (to.name == 'LoginForm' && to.query.code) {
    next()
  } else {
    next({ path: '/LoginForm', query: { redirect: to.fullPath } })

    if (to.path === '/login') {
      next({
        path: '/layout',
      })
    }
  }
})

router.afterEach((to, from) => {
  if (to.meta.title) {
    document.title = to.meta.title //获取路由里面写入的meta里面的title字段
  }
})

/* 解析token，获取当前登录的租户信息 */
function resolveToken() {
  const token = localStorage.getItem('access_token')
  const decodedToken = jwtDecode(token)
  return new Promise((resolve, reject) => {
    console.log(decodedToken, '解析token')
    if (decodedToken.tenant_id == '-1') {
      resolve()
    } else {
      reject()
    }
  })
}
export default router
