import Vue from 'vue'
import Vuex from 'vuex'
import { getAdminInfo } from '@/api/userListServe/userInfo.js'
import { getAction } from '@/utils/request'

import CryptoJS from 'crypto-js'
import { constantRoutes, asyncRoutes } from '../router'
import module from './module'
import notification from './notification'
import user from './user'
import { gotoAuth } from '../utils/login'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userName: sessionStorage.getItem('userName') || '',
    token: sessionStorage.getItem('token') || '',
    // menuLists: JSON.parse(sessionStorage.getItem('menuLists')) || routerMenu,
    menuLists: [],
    admin: false,
    // 通过配角色来控制菜单的显示隐藏
    routes: [],
    addRoutes: [],
    // roles: []
    pageState: null,
    selectedKey: '',
    condition: '',
  },
  getters: {
    token(state) {
      return state.token
    },
    roles(state) {
      return []
    },
    addRouters(state) {
      return state.addRoutes
    },
  },
  mutations: {
    /* 保存资源列表刷新前的筛选条件 */
    saveCondition(state, payload) {
      state.condition = payload
    },

    // 设置用户权限

    // 设置state用户名
    SETUSERNAME(state, value) {
      state.userName = value
    },
    // 设置state里的token
    SETTOKEN(state, value) {
      state.token = value
    },
    // 设置state里菜单
    SETMENULISTS(state, arr) {
      state.menuLists = arr
    },

    /* 是否是管理员 */
    set_Admin(state, value) {
      if (value.length == 0) {
        state.admin = false
      } else {
        let limitArr = value.map((item) => {
          return (item = item.roleCode)
        })
        if (limitArr.includes('admin')) {
          state.admin = 'system'
        } else {
          state.admin = 'false'
        }
      }
    },
  },
  actions: {
    // 获取并设置token
    setTokenAction({ commit }, loginMessage) {
      return new Promise((resolve, reject) => {
        // getAction('./data/user.json').then((res) => {

        commit('SETUSERNAME', loginMessage.name)
        sessionStorage.setItem('userName', loginMessage.name)

        resolve()
      })
      // })
    },
    getToken(context, url) {
      let from = url

      /* 判断跳转的环境 */
      if (process.env.NODE_ENV === 'test') {
        if (window.location.href.includes('8081')) {
          gotoAuth('http://127.0.0.1:8081/LoginForm')
        } else {
          gotoAuth(process.env.VUE_APP_API_OPN_URL + '/LoginForm')
        }
      } else if (
        process.env.NODE_ENV === 'development' ||
        process.env.NODE_ENV === 'production'
      ) {
        gotoAuth(process.env.VUE_APP_API_OPN_URL + '/LoginForm')
      }
    },

    // token解密
    // AES解密
    decrypt(context, word) {
      // 密钥
      const privateKey = CryptoJS.enc.Utf8.parse('21436587A9CBEDF0')
      // 密钥偏移量
      const iv = CryptoJS.enc.Utf8.parse('DEBC9A785634120F')
      let encryptedHexStr = CryptoJS.enc.Hex.parse(word)
      let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
      let decrypt = CryptoJS.AES.decrypt(srcs, privateKey, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      })
      let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
      return decryptedStr.toString()
    },

    // 保存token到sessionStorage
    saveToken(context, TK) {
      // 解密token
      let token = context.commit('decrypt', TK)
      // 保存到sessionStorage里面
      sessionStorage.setItem('token', token)
    },

    /* 获取管理员用户信息 */
    adminInfo() {
      return new Promise((resolve, reject) => {
        getAdminInfo().then((res) => {
          if (res.code === 0) {
            window.sessionStorage.setItem('tid', res.data.tid)

            resolve(res.data.roles)
          } else {
            reject(res.message)
          }
        })
      })
    },
  },
  modules: {
    module,
    notification,
    user,
  },
})
