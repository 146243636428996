/*
 * @Author: XSJ 13592841305@163.com
 * @Date: 2022-07-12 09:29:31
 * @LastEditors: 徐少杰 xushaojie@yihuosoft.com
 * @LastEditTime: 2024-05-30 16:43:28
 * @FilePath: \cz-operation\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import qs from 'qs'
import JsonExcel from 'vue-json-excel'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import './assets/CSS/global.less'
import func from '@/components/bpmnDesign/preload.js'
import moment from 'moment'
import VueClipboard from 'vue-clipboard2'
import SparkMD5 from 'spark-md5'
import Ellipsis from '@/components/Ellipsis'
import unifyAvatar from '@/components/unifyAvatar'

import { baseServer } from './api/servers'
import { loginServe } from './api/loginServe'
// import { userInfoApi } from './api/userInfo.js'
import { resourceServe } from './api/resourceServe'
import { notificationServe } from './api/notificationServe'
import { applicationServe } from './api/appMark'
import { systemServe } from './api/systemServe'
import { tenantManageServe } from './api/tenantManageServe'
import { userListServe } from './api/userListServe'
import { dataManagementServe } from './api/dataManagementServe'

import IconFont from '@/assets/icon/icon.js'
import draggable from 'vuedraggable'

Vue.config.productionTip = false

Vue.component('downloadExcel', JsonExcel)
Vue.component('iconFont', IconFont)
Vue.component('ellipsis', Ellipsis)
Vue.component('uAvatar', unifyAvatar)

Vue.prototype.$func = func
Vue.prototype.$moment = moment

Vue.prototype.$api = {
  ...baseServer,
  ...loginServe,
  // ...userInfoApi,
  ...resourceServe,
  ...notificationServe,
  ...applicationServe,
  ...systemServe,
  ...tenantManageServe,
  ...userListServe,
  ...dataManagementServe,
}

Vue.use(Antd)
Vue.use(VueClipboard)
Vue.use(draggable)

Vue.prototype.$qs = qs
Vue.prototype.$SparkMD5 = SparkMD5

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
