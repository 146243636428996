/*
 * @Author: XSJ 13592841305@163.com
 * @Date: 2022-07-12 09:29:31
 * @LastEditors: XSJ 13592841305@163.com
 * @LastEditTime: 2023-10-12 12:14:46
 * @FilePath: \cz-operation\src\api\loginServe\loginApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { axios } from '@/utils/request'

export function getLoginManageInfo() {
  return axios({
    url: `/platform/my-profile`,
    method: 'get',
  })
}

export function _logout() {
  return axios({
    url: `/auth/logout`,
    method: 'get',
  })
}

/* 获取当前登录用户租户信息 */
export function getCurrentUserTenantInfo() {
  return axios({
    url: `/platform/current-organization/simple-information`,
    method: 'get',
  })
}

export const loginApi = {
  _logout,
  getLoginManageInfo,
  getCurrentUserTenantInfo,
}
