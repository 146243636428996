export default {
  namespaced: true,
  state() {
    return {
      groupMenuKey: '',
      sceneMenuKey: '',
      tabKey: '',
      systemSceneMenuKey: '',
      systemTabKey: '',
      scenarioKey: '',
      systemScenarioKey: '',
      systemTabKey: ''
    }
  },
  getters: {

  },
  mutations: {
    /* 保存更新前的位置状态 */
    savePosition(state, payload) {
      state.groupMenuKey = payload.groupMenuKey !== '' ? payload.groupMenuKey : state.groupMenuKey
      state.sceneMenuKey = payload.sceneMenuKey !== '' ? payload.sceneMenuKey : state.sceneMenuKey
      state.tabKey = payload.tabKey !== '' ? payload.tabKey : state.tabKey
      state.scenarioKey = payload.scenarioKey !== '' ? payload.scenarioKey : state.scenarioKey
    },
    /* 保存更新前的系统消息推送位置状态 */
    savePosition2(state, payload) {
      state.systemSceneMenuKey = payload.sceneMenuKey !== '' ? payload.sceneMenuKey : state.systemSceneMenuKey
      state.systemTabKey = payload.tabKey !== '' ? payload.tabKey : state.systemTabKey
      state.systemScenarioKey = payload.scenarioKey !== '' ? payload.scenarioKey : state.systemScenarioKey

    },
  },
  actions: {}
}