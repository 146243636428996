/*
 * @Author: XSJ 13592841305@163.com
 * @Date: 2023-08-22 10:06:19
 * @LastEditors: XSJ 13592841305@163.com
 * @LastEditTime: 2023-08-22 15:05:13
 * @FilePath: \cz-operation\src\api\systemServe\gatewayRouting.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { axios } from '@/utils/request'
import { stringify } from 'qs'

/* 获取网关路由列表 */
export function getGatewayList(params) {
  return axios({
    url: `/base/gateway/route`,
    method: 'get',
    params,
  })
}

/* 新增网关路由 */
export function addGatewayRoute(data) {
  return axios({
    url: `/base/gateway/route/add`,
    method: 'post',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 修改、编辑网关路由信息 */
export function updateGateway(data) {
  return axios({
    url: `/base/gateway/route/update`,
    method: 'post',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 删除网关路由 */
export function deleteGatewayRoute(routeId) {
  return axios({
    url: `/base/gateway/route/remove`,
    method: 'post',
    data: stringify({ routeId }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export const gatewayRoutingApi = {
  getGatewayList,
  addGatewayRoute,
  updateGateway,
  deleteGatewayRoute,
}
