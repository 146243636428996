const routesConfig = [
  {
    path: '/LoginForm',
    name: 'LoginForm',
    component: () => import('@/views/LoginForm.vue'),
    meta: {
      title: '登录',
    },
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      title: '登录',
    },
  },
  {
    path: '/other',
    name: 'Other',
    component: () => import('@/views/Other.vue'),
    meta: {
      title: '其他',
      permission: ['false'],
    },
  },

  {
    path: '/',
    // redirect: '/login',
    redirect: '/LoginForm',
  },
  {
    path: '/layout',
    component: () => import('@/components/Layout.vue'),
    name: 'Layout',
    redirect: '/layout/tenantManage',
    alwayShow: true,
    meta: {
      title: '首页',
      permission: ['admin', 'system'],
    },
    children: [
      // {
      //   path: '/layout/user',
      //   name: 'User',
      //   // component: RouteView,
      //   meta: {
      //     title: '用户管理',
      //     component: () => import('@/components/routerView.vue'),
      //     icon: '',
      //     keepAlive: false,
      //     permission: ['admin', 'system'],
      //   },
      //   redirect: '/layout/user/userList',
      //   hideChildrenInMenu: false,
      //   children: [
      //     {
      //       path: '/layout/user/userList',
      //       component: () => import('@/views/user/userList.vue'),
      //       // component: () => import('@/views/resourceManagement/resourceUse'),
      //       name: 'UserList',
      //       meta: {
      //         title: '用户列表',
      //         icon: '',
      //         keepAlive: false,
      //         permission: ['admin', 'system'],
      //         fPath: '/layout/user',
      //       },
      //       hideChildrenInMenu: true,
      //     },
      //     {
      //       path: '/layout/user/userStatistics',
      //       component: () => import('@/views/user/userStatistics'),
      //       name: 'UserStatistics',
      //       meta: {
      //         title: '用户统计',
      //         icon: '',
      //         keepAlive: false,
      //         permission: ['admin', 'system'],
      //       },
      //       hideChildrenInMenu: true,
      //     },
      //   ],
      // },

      {
        path: '/layout/tenantManage',
        name: 'TenantManage',
        // component: RouteView,
        meta: {
          title: '租户管理',
          icon: '',
          keepAlive: false,
          permission: ['admin', 'system'],
        },
        redirect: '/layout/tenantManage/tenantList',
        component: () => import('@/components/routerView.vue'),
        hideChildrenInMenu: false,
        children: [
          {
            path: '/layout/tenantManage/tenantList',
            component: () => import('@/views/tenantManage/tenantList'),
            name: 'TenantList',
            meta: {
              title: '租户列表',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/tenantManage',
            },
            hideChildrenInMenu: true,
            children: [],
          },
          {
            path: '/layout/tenantManage/tenantDetail',
            component: () => import('@/views/tenantManage/tenantDetail'),
            name: 'TenantDetail',
            meta: {
              title: '租户详情',
              permission: ['admin', 'system'],
              icon: '',
              keepAlive: false,
              fPath: '/layout/tenantManage',
              hidden: true,
              _path: '/layout/tenantManage/tenantList',
            },
            hideChildrenInMenu: true,
            hidden: true,
          },
          {
            path: '/layout/tenantManage/modifyAuth',
            component: () => import('@/views/tenantManage/modifyAuth'),
            name: 'ModifyAuth',
            meta: {
              title: '企业认证',
              permission: ['admin', 'system'],
              icon: '',
              keepAlive: false,
              fPath: '/layout/tenantManage',
              hidden: true,
              _path: '/layout/tenantManage/tenantList',
            },
            hideChildrenInMenu: true,
            hidden: true,
          },

          {
            path: '/layout/tenantManage/wechatMallManage',
            component: () => import('@/views/tenantManage/wechatMallManage'),
            name: 'WechatMallManage',
            meta: {
              title: '微信特约商户管理',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/tenantManage',
            },
            hideChildrenInMenu: true,
          },
        ],
      },

      {
        path: '/layout/userManage',
        name: 'UserManage',
        // component: RouteView,
        meta: {
          title: '用户管理',
          icon: 'usergroup-add',
          keepAlive: false,
          permission: ['admin', 'system'],
        },
        redirect: '/layout/userManage/userList',
        component: () => import('@/components/routerView.vue'),
        hideChildrenInMenu: false,
        children: [
          {
            path: '/layout/userManage/userList',
            component: () => import('@/views/user/userList'),
            name: 'UserList',
            meta: {
              title: '用户列表',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/userManage',
            },
            hideChildrenInMenu: true,
          },
        ],
      },

      {
        path: '/layout/resource',
        name: 'Resource',
        // component: RouteView,
        meta: {
          title: '资源管理',
          icon: 'folder',
          keepAlive: false,
          permission: ['admin', 'system'],
        },
        redirect: '/layout/resource/resourceUse',
        component: () => import('@/components/routerView.vue'),
        hideChildrenInMenu: false,
        children: [
          {
            path: '/layout/resource/resourceUse',
            component: () => import('@/views/resourceManagement/resourceUse'),
            name: 'ResourceUse',
            meta: {
              title: '资源使用',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/resource',
            },
            hideChildrenInMenu: true,
          },
          {
            path: '/layout/resource/resourceInitialize',
            component: () =>
              import('@/views/resourceManagement/resourceInitialize'),
            name: 'ResourceInitialize',
            meta: {
              title: '资源初始化',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/resource',
            },
            hideChildrenInMenu: true,
          },
          {
            path: '/layout/resource/resourceIntegration',
            component: () =>
              import('@/views/resourceManagement/resourceIntegration'),
            name: 'ResourceIntegration',
            meta: {
              title: '资源封装',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/resource',
            },
            hideChildrenInMenu: true,
          },
          {
            path: '/layout/resource/advertisementPut',
            component: () =>
              import('@/views/resourceManagement/advertisementPut'),
            name: 'AdvertisementPut',
            meta: {
              title: '广告投放',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/resource',
            },
            hideChildrenInMenu: true,
          },
        ],
      },

      {
        path: '/layout/notification',
        name: 'Notification',
        // component: RouteView,
        meta: {
          title: '消息推送',
          icon: 'mail',
          keepAlive: false,
          permission: ['admin', 'system'],
        },
        redirect: '/layout/notification/systemNotification',
        component: () => import('@/components/routerView.vue'),
        hideChildrenInMenu: false,
        children: [
          {
            path: '/layout/notification/systemNotification',
            component: () => import('@/views/notification/systemNotification'),
            name: 'SystemNotification',
            meta: {
              title: '系统消息推送',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/notification',
            },
            hideChildrenInMenu: true,
          },
          {
            path: '/layout/notification/businessNotification',
            component: () =>
              import('@/views/notification/businessNotification'),
            name: 'BusinessNotification',
            meta: {
              title: '业务消息推送',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/notification',
            },
            hideChildrenInMenu: true,
          },
        ],
      },

      {
        path: '/layout/application',
        name: 'Application',
        // component: RouteView,
        meta: {
          title: '应用市场',
          icon: 'dropbox',
          keepAlive: false,
          permission: ['admin', 'system'],
        },
        redirect: '/layout/application/applicationAudit',
        component: () => import('@/components/routerView.vue'),
        hideChildrenInMenu: false,
        children: [
          {
            path: '/layout/application/applicationAudit',
            component: () =>
              import('@/views/applicationMarket/applicationAudit'),
            name: 'ApplicationAudit',
            meta: {
              title: '应用模板审核',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/application',
            },
            hideChildrenInMenu: true,
          },
          {
            path: '/layout/application/applicationManage',
            component: () =>
              import('@/views/applicationMarket/applicationManage'),
            name: 'applicationManage',
            meta: {
              title: '应用模板管理',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/application',
            },
            hideChildrenInMenu: true,
          },
          {
            path: '/layout/application/applicationRegister',
            component: () =>
              import('@/views/applicationMarket/applicationRegister'),
            name: 'ApplicationRegister',
            meta: {
              title: '登记高级业务',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/application',
            },
            hideChildrenInMenu: true,
          },
        ],
      },

      /* --- */

      // {
      //   path: '/layout/systemManage',
      //   name: 'SystemManage',
      //   // component: RouteView,
      //   meta: {
      //     title: '系统管理',
      //     icon: 'slack-square',
      //     keepAlive: false,
      //     permission: ['admin', 'system'],
      //   },
      //   redirect: '/layout/systemManage/appManage',
      //   component: () => import('@/components/routerView.vue'),
      //   hideChildrenInMenu: false,
      //   children: [
      //     {
      //       path: '/layout/systemManage/appManage',
      //       component: () => import('@/views/systemManage/appManage'),
      //       name: 'AppManage',
      //       meta: {
      //         title: '应用管理',
      //         icon: '',
      //         keepAlive: false,
      //         permission: ['admin', 'system'],
      //         fPath: '/layout/systemManage',
      //       },
      //       hideChildrenInMenu: true,
      //     },
      //     {
      //       path: '/layout/systemManage/apiList',
      //       component: () => import('@/views/systemManage/apiListManage'),
      //       name: 'ApiList',
      //       meta: {
      //         title: 'API列表',
      //         icon: '',
      //         keepAlive: false,
      //         permission: ['admin', 'system'],
      //         fPath: '/layout/systemManage',
      //       },
      //       hideChildrenInMenu: true,
      //     },
      //     {
      //       path: '/layout/systemManage/gatewayRouting',
      //       component: () => import('@/views/systemManage/gatewayRouting'),
      //       name: 'GatewayRouting',
      //       meta: {
      //         title: '网关路由',
      //         icon: '',
      //         keepAlive: false,
      //         permission: ['admin', 'system'],
      //         fPath: '/layout/systemManage',
      //       },
      //       hideChildrenInMenu: true,
      //     },
      //   ],
      // },

      {
        path: '/layout/systemManage',
        name: 'SystemManage',
        // component: RouteView,
        meta: {
          title: '系统管理',
          icon: 'slack-square',
          keepAlive: false,
          permission: ['admin', 'system'],
        },
        redirect: '/layout/systemManage/appManage',
        component: () => import('@/components/routerView.vue'),
        hideChildrenInMenu: false,
        children: [
          {
            path: '/layout/systemManage/appManage',
            component: () => import('@/views/systemManage/appManage'),
            name: 'AppManage',
            meta: {
              title: 'APP管理',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/systemManage',
            },
            hideChildrenInMenu: true,
          },
          {
            path: '/layout/systemManage/apiList',
            component: () => import('@/views/systemManage/apiListManage'),
            name: 'ApiList',
            meta: {
              title: 'API列表',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/systemManage',
            },
            hideChildrenInMenu: true,
          },
          {
            path: '/layout/systemManage/gatewayRouting',
            component: () => import('@/views/systemManage/gatewayRouting'),
            name: 'GatewayRouting',
            meta: {
              title: '网关路由',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/systemManage',
            },
            hideChildrenInMenu: true,
          },
        ],
      },

      {
        path: '/layout/dataManagement',
        name: 'DataManagement',
        // component: RouteView,
        meta: {
          title: '数据治理',
          icon: 'mail',
          keepAlive: false,
          permission: ['admin', 'system'],
        },
        redirect: '/layout/dataManagement/dataDictionary',
        component: () => import('@/components/routerView.vue'),
        hideChildrenInMenu: false,
        children: [
          {
            path: '/layout/dataManagement/dataDictionary',
            component: () => import('@/views/dataManagement/dataDictionary'),
            name: 'DataDictionary',
            meta: {
              title: '数据字典',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/dataManagement',
            },
            hideChildrenInMenu: true,
          },
          {
            path: '/layout/dataManagement/dataTask',
            component: () => import('@/views/dataManagement/dataTask'),
            name: 'DataTask',
            meta: {
              title: '任务调度',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/dataManagement',
            },
            hideChildrenInMenu: true,
          },
          {
            path: '/layout/dataManagement/taskLog',
            component: () => import('@/views/dataManagement/taskLog'),
            name: 'TaskLog',
            meta: {
              title: '任务调度日志',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/dataManagement',
              hidden: true,
              _path: '/layout/dataManagement/dataTask',
            },
            hideChildrenInMenu: true,
            hidden: true,
          },
          {
            path: '/layout/dataManagement/dataProcessing',
            component: () => import('@/views/dataManagement/dataProcessing'),
            name: 'DataProcessing',
            meta: {
              title: '数据处理',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/dataManagement',
            },
            hideChildrenInMenu: true,
          },
          {
            path: '/layout/dataManagement/processingLog',
            component: () => import('@/views/dataManagement/processingLog'),
            name: 'ProcessingLog',
            meta: {
              title: '数据处理日志',
              icon: '',
              keepAlive: false,
              permission: ['admin', 'system'],
              fPath: '/layout/dataManagement',
              hidden: true,
              _path: '/layout/dataManagement/dataProcessing',
            },
            hideChildrenInMenu: true,
            hidden: true,
          },
        ],
      },
    ],
  },
]

export const menu = routesConfig[4].children

export default routesConfig
