import { axios } from '@/utils/request'
import { stringify } from 'qs'


/* 应用包列表 */
export function getAppPageList(data) {
  return axios({
    url: `/warestore/application-packages/page`,
    method: 'post',
    headers: {
      "Content-Type": 'application/json'
    },
    data,
  })
}

/* 应用包审核 */
export function appPackageExamine(packageId, data) {
  return axios({
    url: `/warestore/application-packages/${packageId}/examine`,
    method: 'put',
    data
  })
}

/* 应用包上下架 */
export function appPackageLaunch(packageId, data) {
  return axios({
    url: `/warestore/application-packages/${packageId}/set-launching`,
    method: 'put',
    data
  })
}

/* 批量获取应用信息 */
export function getAppInfo(ids) {
  return axios({
    url: `/bigtable/applications`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',

    },
    data: stringify({
      ids,
    })
  })
}

/* 批量下架应用包 */
export function batchTakedown(data) {
  return axios({
    url: `/warestore/application-packages/set-launching/batch`,
    method: 'put',
    data,
  })
}

/* 删除应用包 */
export function deleteAppPackage(packageId) {
  return axios({
    url: `/warestore/application-packages/${packageId}`,
    method: 'delete'
  })
}


export const applicationApi = {
  getAppPageList,
  appPackageExamine,
  appPackageLaunch,
  getAppInfo,
  deleteAppPackage,
  batchTakedown,
}