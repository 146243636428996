export default {
  namespaced: true,
  state() {
    return {
      searchCondition_x: null,
      pageSize_x: 0,
      current_x: 0,
      sortMethod_x: '',
      isUsePrevCondition: false
    }
  },
  getters: {

  },
  mutations: {
    saveCondition(state, payload) {
      let { pageSize, current, sortMethod, ...sCondition } = payload
      state.searchCondition_x = sCondition
      state.pageSize_x = pageSize
      state.current_x = current
      state.sortMethod_x = sortMethod
    },
    usePrevCondition(state, payload) {
      state.isUsePrevCondition = payload
    }
  },
  actions: {}
}