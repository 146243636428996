/* eslint-disable space-before-blocks */
import { axios } from '@/utils/request'
import { stringify } from 'qs'
export const userInfoApi = {
  newMsgRegisterSend,
  codeVerify,
  reSetPassword,
  msgRegisterSend,
  modifyAccount,
  blockUpUser,
  recoverUser,
  lastTime,
  /* ----------新架构 */
  getAllUsersList,
  _getUserChannel,
  getTenantListByUser,
  quitTenant,
  unbind,
  getUserAccountList,
}

/* 发送验证码 */
export function msgRegisterSend(data) {
  return axios({
    url: '/msg/register/send',
    method: 'POST',
    params: {
      ...data,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 用户停用 */
export function blockUpUser(userId) {
  return axios({
    url: `/platform/managements/users/${userId}/disable`,
    method: 'post',
  })
}

/* 用户启用 */
export function recoverUser(userId) {
  return axios({
    url: `/platform/managements/users/${userId}/enable`,
    method: 'post',
  })
}

/* 用户最后登录时间 */
export function lastTime(userId) {
  return axios({
    url: `/admin/last-login-log/${userId}`,
    method: 'get',
  })
}

/* 修改账号 */
export function modifyAccount(data) {
  return axios({
    url: `${process.env.VUE_APP_AUTH_API}/managements/accounts/change-bind-phone`,
    method: 'post',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 重置/修改用户密码 */
export function reSetPassword(data) {
  return axios({
    url: `${process.env.VUE_APP_AUTH_API}/managements/accounts/change-password`,
    method: 'post',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* --------------新架构接口 */

/* 获取用户列表 */
export function getAllUsersList(params) {
  return axios({
    url: `/platform/managements/users`,
    method: 'get',
    params,
  })
}

/* 获取用户来源（用户渠道） */
export function _getUserChannel() {
  return axios({
    url: `/platform/managements/user-sources`,
    method: 'get',
  })
}

// 发送验证码（新）
export function newMsgRegisterSend(params) {
  return axios({
    url: `/captcha/verification-code/send`,
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

/* 校验验证码 */
export function codeVerify(data) {
  return axios({
    url: `/captcha/verification-code/verify`,
    method: 'post',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 获取用户所属/已加入的组织列表信息 */
export function getTenantListByUser(userId) {
  return axios({
    url: `/platform/managements/users/${userId}/organizations`,
    method: 'get',
  })
}

/* 强制用户退出组织 */
export function quitTenant(tenantId, userId) {
  return axios({
    url: `/platform/tenants/${tenantId}/users/${userId}`,
    method: 'delete',
  })
}

/* 解绑微信、解绑邮箱 */
export function unbind(data) {
  return axios({
    url: `${process.env.VUE_APP_AUTH_API}/managements/accounts/unbind`,
    method: 'post',
    // data,
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 获取用户账号列表 */
export function getUserAccountList(userId) {
  return axios({
    url: `${process.env.VUE_APP_AUTH_API}/managements/user-accounts/${userId}`,
    method: 'get',
  })
}
