import Vue from 'vue'
import axios from 'axios'
import $router from '@/router'
import store from '@/store'

import { stringify } from 'qs'
// 读取
export function getAction(url, parameter) {
  return axios({
    url,
    method: 'get',
    params: parameter,
    // paramsSerializer序列化
    paramsSerializer: (params) => {
      return stringify(params, { indices: false })
      //  stringify({ids: [1,2,3]},{indices: false})
      //  形式： ids=1&ids=2&ids=3
    },
  })
}

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
})

const err = (error) => {
  if (error.response) {
    const data = error.response.data

    const token = sessionStorage.getItem('token')
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message,
      })
    }
    if (
      error.response.status === 401 &&
      !(data.result && data.result.isLogin)
    ) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed',
      })
      if (token) {
        // store.dispatch('Logout').then(() => {
        //   Vue.prototype.$message.warning('登录信息失效，将退出登录！')
        //   setTimeout(() => {
        //     window.location.reload()
        //   }, 1500)
        // })
      }
    }
  }
  return Promise.reject(error)
}

// 请求拦截器
service.interceptors.request.use((config) => {
  // let token = sessionStorage.getItem('token')
  let token = localStorage.getItem('access_token')

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
    // config.headers['Access-Token'] = token
  }

  if (config.method === 'get' || config.method === 'GET') {
    config.data = { unused: 0 }
  }

  // if (
  //   ['post', 'POST'].includes(config.method) &&
  //   config.headers['Content-Type'].includes('x-www-form-urlencoded')
  // ) {
  //   config.data = stringify(config.data)
  // }

  return config
}, err)

// 相应拦截器
service.interceptors.response.use(
  (response) => {
    // console.log(response)
    if (response.headers['content-disposition']) {
      return response
    }
    return response.data
  },
  // 相应错误信息捕获
  (error) => {
    console.log(error.response.status, 'error')
    switch (error.response.status) {
      case 400:
        Vue.prototype.$message.error(error.response.data.message, 1)
        break
      case 401:
        Vue.prototype.$message.error('登录已过期，请重新登录！')
        /* 登录过期，跳转auth重新登录 */
        sessionStorage.clear()
        localStorage.clear()
        const homePage = window.location.href
        const authUrl =
          process.env.VUE_APP_AUTH_API +
          `/sessions/logout?post_logout_redirect_uri=${homePage}`
        window.location.replace(authUrl)
        break
      case 403:
        Vue.prototype.$message.error(error.response.data.message, 1)
        break
      case 500:
        Vue.prototype.$message.error(error.response.data.message, 1)
        break
      case 503:
        Vue.prototype.$message.error(error.response.data.message, 1)
        break
    }
    // return Promise.reject(error)
    // console.log(error.response)
    return error.response.data
  }
)

export { service as axios }
