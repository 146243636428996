<template>
  <div style="display: inline-block">
    <div class="picbox" :style="{ height, 'line-height': height }">
      <img
        v-if="picUrl"
        alt=""
        class="c-avatar"
        :src="picUrl && picUrl.includes('http') ? picUrl : targetAvatar"
        :style="{ height, width, borderRadius: '50%' }"
        :onerror="errorImage"
      />
      <div
        v-else
        class="script-avatar"
        style="border-radius: 50%"
        :style="{ height, width, 'line-height': height }"
      >
        {{ name ? name.substr(0, 1) : 'user' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'unifyAvatar',
  props: {
    picUrl: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '24px',
    },
    height: {
      type: String,
      default: '24px',
    },
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      targetAvatar: '',
      errorImage: 'this.src="' + require('@/assets/default/default-user.svg') + '"',
    }
  },
}
</script>

<style lang="less" scoped>
.script-avatar {
  background-color: #0059ff !important;
  color: #fff !important;
  text-align: center !important;
}
</style>
