/*
 * @Author: XSJ 13592841305@163.com
 * @Date: 2023-06-26 16:15:27
 * @LastEditors: XSJ 13592841305@163.com
 * @LastEditTime: 2023-07-25 17:59:14
 * @FilePath: \cz-operation\src\api\resourceServe\integration.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { axios } from '@/utils/request'

/* 查询资源封装 */
export function getIntegrationList() {
  return axios({
    url: `/warestore/v1/resource/bindings`,
    method: 'get',
  })
}

/* 创建资源封装 */
export function createdIntegration(data) {
  return axios({
    url: `/warestore/v1/resource/bindings`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

/* 编辑、修改资源封装 */
export function updateIntegration(bindingId, data) {
  return axios({
    url: `/warestore/v1/resource/bindings/${bindingId}`,
    method: 'put',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

/* 删除资源封装 */
export function deleteIntegration(bindingId) {
  return axios({
    url: `/warestore/v1/resource/bindings/${bindingId}`,
    method: 'delete',
  })
}

/* 获取资源配置类型数据 */
export function getResourceTypes() {
  return axios({
    url: `/warestore/v1/resource/enums/resource-types`,
    method: 'get',
  })
}

export const integrationApi = {
  getIntegrationList,
  createdIntegration,
  updateIntegration,
  deleteIntegration,
  getResourceTypes,
}
