/*
 * @Author: 徐少杰 xushaojie@yihuosoft.com
 * @Date: 2024-05-17 10:42:18
 * @LastEditors: 徐少杰 xushaojie@yihuosoft.com
 * @LastEditTime: 2024-05-27 14:48:06
 * @FilePath: \cz-operation-web\src\api\dataManagementServe\dataDictionaryApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { axios } from '@/utils/request'
import { stringify } from 'qs'

/* 获取数据字典列表 */
export function getDictionaryList(params) {
  return axios({
    url: '/dict/dictType/list',
    method: 'GET',
    params,
  })
}

/* 获取字典对应键值数据列表 */
export function getDictDataList(params) {
  return axios({
    url: '/dict/dictData/list',
    method: 'GET',
    params,
  })
}

/* 删除字典 */
export function deleteDict(params) {
  return axios({
    url: '/dict/dictType/remove',
    method: 'post',
    data: stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 修改字典 */
export function modifyDict(data) {
  return axios({
    url: '/dict/dictType/update',
    method: 'POST',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 删除字典键值对 */
export function deleteDictKeyVal(params) {
  return axios({
    url: '/dict/dictData/remove',
    method: 'POST',
    data: stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 修改字典键值对 */
export function modifyDictKeyVal(data) {
  return axios({
    url: '/dict/dictData/update',
    method: 'post',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

// 数据字典模糊查询接口
export function dictTypeLikelist(data) {
  return axios({
    url: '/dict/dictType/likelist',
    method: 'post',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

// 添加字典表数据
export function addDict(data) {
  return axios({
    url: '/dict/dictType/add',
    method: 'post',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

// 添加字典数据表数据
export function addDictKeyVal(data) {
  return axios({
    url: '/dict/dictData/add',
    method: 'POST',
    data: stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export const dataDictionaryApi = {
  getDictionaryList,
  getDictDataList,
  deleteDict,
  modifyDict,
  deleteDictKeyVal,
  modifyDictKeyVal,
  dictTypeLikelist,
  addDict,
  addDictKeyVal,
}
