/*
 * @Author: 徐少杰 xushaojie@yihuosoft.com
 * @Date: 2024-05-17 11:17:59
 * @LastEditors: 徐少杰 xushaojie@yihuosoft.com
 * @LastEditTime: 2024-05-17 12:13:49
 * @FilePath: \cz-operation-web\src\api\dataManagementServe\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { dataDictionaryApi } from './dataDictionaryApi.js'
import { dataTaskAndProcessingApi } from './dataTaskApi.js'

export const dataManagementServe = {
  ...dataDictionaryApi,
  ...dataTaskAndProcessingApi,
}
