import { axios } from '@/utils/request'
import Axios from 'axios'

// 上传文件
export function uploadFile(file) {
  return axios({
    url: `/file/fileoperation/upload`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: file,
  })
}

// 文件模板下载
export function downloadFile() {
  return axios({
    url: `/device/device/model/download`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    responseType: 'blob',
  })
}

// 批量下载固件版本文件
export function downloadFirmware(fileIdList) {
  return axios({
    url: `/file/fileoperation/downloadall`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    params: {
      fileIds: fileIdList.join(','),
    },
    responseType: 'blob',
  })
}

/* 根据文件id下载文件 */
export function downloadOneFile(fileId, isDownload) {
  return axios({
    url: `/file/fileoperation/download`,
    method: 'get',
    params: {
      fileId: fileId,
      isDownload: isDownload,
    },
    responseType: 'blob',
  })
}

/* 新的文件上传-预上传（预上传后向返回的地址发送上传请求） */
// data ={
// 	"fileSize": 0,
// 	"fileType": 0,
// 	"isMedia": 0,
// 	"originFileName": "",
// 	"tenantId": 0
// }
export function preUpload(data) {
  return axios({
    url: `/storage/fileStorage/preUpload`,
    method: 'post',
    data,
  })
}

/* xin上传文件(应用文件) */
export function newUploadFile(url, file) {
  return axios({
    url: url,
    method: 'put',
    headers: {
      'Content-Type': 'application/vnd.android.package-archive',
    },
    data: file,
  })
}
/* 上传飞应用文件（图片，视频） */
export function _newUploadFile(url, file) {
  return axios({
    url: url,
    method: 'put',
    data: file,
  })
}

/* 保存文件（新的上传文件后保存文件） */
export function newSaveFile(data) {
  return axios({
    url: `/storage/fileStorage/saveFile`,
    method: 'post',
    data,
  })
}

/* 保存文件，返回文件ID */
export function saveFileReturnID(data, isNamed) {
  return axios({
    url: `/storage/fileStorage/saveReturnId?isNamed=${isNamed}`,
    method: 'post',
    data,
  })
}

// 文件预下载
export function preDownloadFile(fileId, level = 0) {
  return axios({
    url: '/storage/fileStorage/preDownload',
    method: 'GET',
    data: { fileId, level },
  })
}

/* 文件详情（通过ids） */
export function getFileDetail(id) {
  return axios({
    url: `/storage/fileStorage/getByIds`,
    method: 'post',
    data: {
      fileId: id,
      tenantId: sessionStorage.getItem('tid'),
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export const fileApi = {
  uploadFile,
  downloadFile,
  downloadFirmware,
  downloadOneFile,
  preUpload,
  newUploadFile,
  newSaveFile,
  _newUploadFile,
  saveFileReturnID,
  getFileDetail,
}
